
@import 'src/variables';
.payment-method-content{
    flex-grow: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    .payment-btn {
        align-self: flex-end;
        width: 402px;
        height: 73px;
        background: #6A29FF;
        border-radius: 8px;
        color: #ffffff;
        cursor: pointer;
        border: none;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
    }

    .payment-btn:hover {
        background: #6A29FF;
        color: #ffffff;
    }
}

// main

.payment-method-modal {
    
    .ant-modal-content {
        display: flex;
        border-radius: 10px;
    }

    .ant-modal-body{
        min-height: 570px;
        background: #F7F8FE;
        display: flex;
        padding: 0;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;

        .add-payment-methods-wrapper{
            display: flex;
            gap: 16px;
            
            .payment-method-icon {
                border: none;
                padding: 0;
            }
        }
        .payment-method-page-wrapper {
            padding: 80px 48px 48px;
        }
        
    }
    
    .ant-modal-header, .ant-modal-footer {
        display: none;
    }
    
    .ant-modal-close-x {
        padding-right: 46px;
        padding-top: 18px;
    }
    .ant-modal-close-icon {
            // padding: 46px;
    }
    border-radius: 10px;
    width: 60% !important;
    .add-payment-method-footer {
        position: absolute;
        bottom: 30px;
        right: 30px;
        .btn-continue {
            box-sizing: content-box;
            padding: 10px 40px;
            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
            border-radius: $border-radius-md;
            background: #6A29FF;
            border: none;
            color: #fff;
            cursor: pointer;
        }
    }
    .add-payment-method-header {
        display: flex;
        justify-content: flex-end;
        .modal-close-btn {
            color: #C1C4D5;
           
        }
    }

}

