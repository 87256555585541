.social-signin-wrapper {
  display: flex;
  justify-content: center;
  gap: 22px;
  margin-top: -20px;

  .need-support {
    margin-top: 15px;
    margin-left: -20px;
  }
  .social-button {
    padding-left: 0;
    border: none;
    width: 48px;
    height: 48px;
  }
  .social-button-disabled{
    opacity: 50%;
    padding-left: 0;
    border: none;
    width: 48px;
    height: 48px;
    cursor:not-allowed;
  
  }
 .social-icon {
    width: 48px;
    height: 48px;
  }
}
.need-support {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 22px;
}
.popupModal{
  .ant-modal-title{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0px;
  }
  .modal-close-btn {
    border: none;
    background: #ffff;
    cursor: pointer;
    
  }
  .ant-modal-header{
    border-radius:24px;
  }
  .ant-modal-content{
    border-radius:24px;
  }
 
.modal1-title-bar {
  border-radius: 8px;
  text-align: center;
  .logo-wrapper{
    display: flex;
    justify-content: center;
    .logo-containr{
    height:70px;
    width:70px;
 
  }}
  .modal1-title{
    font-size:34px;
    font-weight:700;
    text-align: center;
    border-radius: 8px;
  }
 
 
}
.terms-text-common {
  height:100px;
  text-align: center;
  padding-top: 10px;
  border-radius: 8px;
  .terms-text {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    a {
      color: #000000;
      font-weight: 700;
    }
  }
 
}

  
.agree-btn-wrapper{
  text-align: center;
  .terms-check-box,
  .terms-check-box:hover {
    //color: #000000;
    font-size: 16px;
    font-weight: 700;
    background-color: #6a29ff;
    color:#ffffff;
    border-radius: 8px;
    width:200px;
    height:50px;
    margin:auto;
    border:none;
  }
 
}

}
.termsModal{
  .btn-wrapper{
    text-align:center;
    .close-btn{
      height:50px;
      width:300px;
      border-radius:8px;
      background-color:#6a29ff;
      color:#ffff;
      font-weight: 700;
    }
  }
}