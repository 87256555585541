.create-room-badge{
    display:flex;
    flex-direction: row;
    justify-content: center;
    gap:100px;
    height:45px;
    background-color:#FF4F4F;
    color:#ffffff;
    align-items: center;
    .upgrade-btn,
    .upgrade-btn:hover{
        background: #FFFFFF;
        border-radius: 20px;
        color:#000000;
        border:none;
    }
}