.hudStats-layout {
  width: 30%;
  position: absolute;
  float: left;
  margin-left: 6%;
  z-index: 20;
  background-color: rgba(76, 76, 76, 0.85);
  .close-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .stats-heading {
      margin: 5px;
      color: #fff;
      font-weight: 800;
    }
    .ant-btn-link {
      color: #fff;
    }
  }
}

.hud-stats {
  display: flex;
  align-items: center;
  gap: 50%;
  margin-top: 30px;
  padding: 10px;
}

.stat {
  padding-left: 15px;
  display: flex;
  align-items: center;
  color: white;
}
.media-stats-scroll {
  overflow-y: auto;
  max-height: 420px;
  margin-top: 30px;
}
.media-stats-scroll::-webkit-scrollbar-track {
  background-color: #d4d4d5;
}
