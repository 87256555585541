.select-room-content{
    margin: 20px;
    display: flex;
    flex-direction: column;

    .select-room-logo{
        height: 42px;
    }

    .select-room {
        margin: 20px 0 20px;

    button.disabled {
        color: rgba(0, 0, 0, 0.16);
        cursor: not-allowed;
    }
    }

    .viewer-stream-info {
        flex-grow: 1;
        .title-wrapper{
            display:flex;
            flex-direction:row;
            gap:20px;
            .copy-btn{
                border:none;
                color:#7E7C7F;
                background:#ffff;
                margin-top:-4px;
                border-top:0px;
                border-bottom:0px;
                height:24px;
                cursor:pointer;
                .copy-icon{
                    width:20px;
                    height:24px;
                }
            }
        }
    }

    .subscription-options-wrapper {
        .subscription-actions-title{
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 100%;
            color: #000000;
            margin:10px 0 14px;
        }
        .remove-subscription-btn {
            border: none;
            color: #FF5A5A;
            padding: 0;
            cursor: pointer;
            height: 14px;
        }

        .remove-subscription-btn span{
            font-size: 14px;
            text-decoration: underline;
        }
        .back-button {
            border: none;
            padding-left: 0px;
            background: none;
            cursor: pointer;
        }
       
        

        // .remove-subscription-btn span:hover {
        //     text-decoration: underline;
        // }
    }

//     .ant-select{
//         border-radius: 8px !important;
//         border: none !important;

//         .ant-select-item-option {
//             height: 49px !important;
//             border: 1px solid #EDEDED !important;
//         }
    
//     .ant-select-selector {
//         background-color: #F7F8FE !important;
//         height: 68px !important;
//         display: flex;
//         align-items: center;

//         .ant-select-selection-item {
//             height: 27px;
//             font-style: normal;
//             font-weight: 400;
//             font-size: 20px;
//             line-height: 27px;

//         }
//     }
// }
}

.add-room-btn{
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #3D39FF;
    width: 100%;
    text-align: left;
}

 .add-room-btn:disabled {
    color: rgba(0, 0, 0, 0.16) ;
    cursor: not-allowed;
}


 .option-disabled{
    color:#000000;
    background-color:#F7F8FE;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    cursor:not-allowed;
 }
 .ant-select-item-group {
    font-weight: 600 !important;
    font-size: 14px !important;
 }
 .copy-modal{
    .ant-modal-content{
        border-radius: 8px;
    }
    .ant-modal-header{
        border-radius: 8px;
        height:50px;
    }
    .ant-modal-body{
        background-color: #EDECEC;
        border-radius: 8px;
        height:300px;
    }
    .ant-modal-footer{
        display:flex;
        flex-direction: row-reverse;
        gap:15px;
        .ant-btn-primary{
            background-color: #0D6FE9;
        }
        .ant-btn.ant-btn-default {
            border-radius: 8px;
        }
    }
}
.ant-tooltip .ant-tooltip-inner {
    color: #1890ff;
    background-color: #ffff;
}

.ant-tooltip-arrow-content{
    --antd-arrow-background-color: #ffff !important;
}
.need-support-copy-text {
    color: #1890ff;
    cursor: pointer;
}