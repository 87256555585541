.ant-layout,
.ant-row {
  height: 100%;
}

$primary-color: #000;
$secondary-color: #141414;
$sidebar-color: #000;

.header {
  color: #fff;
  padding-inline: 0px;
  line-height: 64px;
  background-color: $primary-color;
  cursor: pointer;
  display: flex;
  height: 80px;
  justify-content: space-between;
  z-index: 100;
  padding: 0px !important;

  span {
    margin-left: 8px;
  }
}

.zoom-viewer-header {
  color: #fff;
  padding-inline: 0px;
  line-height: 64px;
  background-color: $primary-color;
  cursor: pointer;
  display: flex;
  height: 80px !important;
  justify-content: space-between;
  z-index: 100;
  padding: 0px !important;

  span {
    margin-left: 8px;
  }
}

.video-tray-content {
  text-align: center;
  min-height: 120px;
  line-height: 120px;
  color: #fff;
  background-color: $primary-color;
}

.viewer-side-panel {
  text-align: left;
  line-height: 100%;
  color: #fff;
  background-color: $sidebar-color;
  min-width: 360px !important;
}

.footer {
  text-align: center;
  color: #fff;
  background-color: $secondary-color;
}

.layout {
  border-radius: 8;
  overflow: hidden;
}

.chat {
  float: right;
}

.zoom-show-new-viewer {
  background-position: 50%;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  width: 98%;
}

.zoom-viewer-header .topbar {
  background-color: #000;
  display: flex;
  height: 80px;
  justify-content: space-between;
  z-index: 100;
  flex-grow: 1;
}

.logo-viewer {
  position: absolute;
  opacity: 1;
  cursor: pointer;
}

.zoom-show-new-viewer .centerMenuTop {
  align-items: flex-start;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  justify-content: center;
  padding: 12px;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item {
  align-items: center;
  background-color: #141414;
  border-bottom: 3px solid #0000;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 56px;
  justify-content: center;
  min-width: 56px;
  position: relative;
  transition: .4s;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item>span {
  display: none;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item:hover {
  background-color: #454248;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item:hover img {
  filter: brightness(0) invert(1);
}

.zoom-show-new-viewer .menuTopRight {
  width: max-content;
  display: flex;
  flex-shrink: 0;
}

.zoom-show-new-viewer .menuTopRight ul {
  list-style-type: none;
  padding: 0px;
  margin: 0 auto;
}

.zoom-show-new-viewer .menuTopRight ul li {
  float: left;
  width: 56px;
}

.zoom-show-new-viewer .menuTopRight ul li a {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-bottom: 3px solid transparent;
  position: relative;
  margin-top: 3px solid transparent;
  cursor: pointer;
}

.zoom-show-new-viewer .menuTopRight ul li a:hover,
.zoom-show-new-viewer .menuTopRight ul li a.active {
  background-color: #454248;
  border-color: #6600ff;
}

.zoom-show-new-viewer .tc_image {
  width: 25px;
  height: 25px;
  margin-top: 15px;
}

.zoom-header-menu .header-menu {
  margin-left: 8px !important;
}

.zoom-header-menu .header-menu span {
  margin-left: 0px;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item:hover>span {
  background: #000;
  bottom: -100%;
  color: #fff;
  display: inline;
  display: initial;
  font-size: 14px;
  height: 24px;
  opacity: 0;
  padding: 0 5px;
  position: absolute;
  text-transform: capitalize;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  z-index: 10;
}


.zoom-show-new-viewer .call-options-dropdown>div>i {
  display: none !important;
}

.zoom-show-new-viewer .call-options-dropdown>div {
  position: unset !important;
}

.zoom-show-new-viewer .pencil-tool-dropdown>div>i {
  display: none !important;
}

.zoom-show-new-viewer .pencil-tool-dropdown>div {
  position: unset !important;
}

.zoom-show-new-viewer .audiovisual-source-dropdown>div>i {
  display: none !important;
}

.zoom-show-new-viewer .audiovisual-source-dropdown>div {
  position: unset !important;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item>span {
  display: none;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item:hover {
  background-color: #454248;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item:hover img {
  filter: brightness(0) invert(1);
}


.zoom-show-new-viewer .centerMenuTop .top-menu-button-item:hover span {
  bottom: -35px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item span::before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid black;
  content: "";
  top: -7px;
  position: absolute;
  left: 50%;
  margin-left: -5px;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item a {
  height: 28px;
  position: absolute;
  padding: 0 25px;
  color: #fff;
}

.zoom-show-new-viewer .centerMenuTop .top-menu-button-item a:hover {
  color: #b2b2b2;
}

.zoom-show-new-viewer .cameraMenu {
  color: black;
  background-color: white;
  font-size: 15px;
  font-weight: normal;
  text-decoration: none;
}

.zoom-show-new-viewer .ui.dropdown .cameraMenu {
  top: 100% !important;
  left: 0px;
}

.zoom-show-new-viewer .ui.dropdown .visible.cameraMenu {
  display: flex !important;
  flex-direction: column;
  padding: 12px;
  background-color: #1d1d1d !important;
  gap: 12px;
  border: 1px solid #454248;
}

.zoom-show-new-viewer .ui.dropdown .cameraMenu>div {
  display: none;
}

.zoom-show-new-viewer .ui.dropdown .visible.cameraMenu>div {
  display: block;
}

.zoom-show-new-viewer ui.dropdown .cameraMenu>.item .dropdown.icon {
  margin: -7px !important;
  ;
}

.zoom-show-new-viewer .cameraMenu .ui.selection.active.dropdown .menu {
  border: 1px solid #707070;
}

.zoom-show-new-viewer .cameraMenu .ui.selection.dropdown .menu>.item {
  border-bottom: 1px solid #707070 !important;
  border-top: none;
  white-space: nowrap;
}

.zoom-show-new-viewer .cameraMenu .ui.dropdown .menu>.item {
  padding: 0px !important;
  display: flex;
  align-items: center;
  background: white !important;
  border-radius: 4px;
}

.zoom-show-new-viewer .cameraMenu .ui.dropdown .menu>.item>button {
  min-width: 100%;
  width: max-content;
  display: flex;
  align-items: center;
}

.zoom-show-new-viewer .cameraMenu .ui.selection.dropdown .menu>.item:last-child {
  border-bottom: none !important;
}

.zoom-show-new-viewer .cameraMenu .ui.selection.dropdown .menu>.item:first-child {
  border-top: 1px solid #707070 !important;
}

.zoom-show-new-viewer .cameraMenu .ui.selection.dropdown .menu>.item .text {
  opacity: 1;
  color: #f4f4f4;
  background: #000;
  width: 100%;
  height: 100%;
}

.zoom-show-new-viewer .cameraMenu .ui.selection.dropdown .menu>.item .text:hover {
  background: #454248;
}

.zoom-show-new-viewer .cameraMenu .centerMenuTop ul li span::before {
  content: none !important;
}

.zoom-show-new-viewer #cameraDropdown {
  color: black;
  background-color: white;
  height: 25px;
  width: 100%;
  padding: 15px;
}

.zoom-show-new-viewer #cameraDropdown>div.visible.menu.transition {
  top: 100% !important;
  left: 0px;
}

.zoom-show-new-viewer #cameraDropdown>div.visible.menu.transition>div.item {
  padding: 12px !important;
  background-color: #1d1d1d !important;
}

.zoom-show-new-viewer #cameraDropdown>div.visible.menu.transition>div.active.selected.item {
  background-color: #282829 !important;
}

.zoom-show-new-viewer #cameraDropdown>div.visible.menu.transition>div.item>span.text {
  background-color: transparent !important;
  color: #ccc !important;
}

.zoom-show-new-viewer #cameraDropdown>div.visible.menu.transition>div.active.selected.item {
  color: white !important;
}

.show-new-viewer .chat-message-grey-bg {
  text-align: left;
}

.zoom-viewer-header .menuTopRight ul li {
  float: left;
  width: 56px;
  display: flex;
  align-items: center;
  height: 100%;
}
.eraser-on, .pencil-on {
  background-color: #454248 !important;
  border-bottom: 3px solid #fff !important;
}
.pencil-on img,
.eraser-on img {
  filter: brightness(0) invert(1);
}
.canvas-pencil-on {
  cursor: url("../../pages/Viewer/Images/pencil.png") 1 30, auto;
}

.canvas-eraser-on {
  cursor: url("../../pages/Viewer/Images/erase.png") 1 30, auto;
}