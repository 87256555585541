.access-code-input {
  max-width: 500px;
}
.viewer-btn-wrapper {
    

  .viewer-login-btn,
  .viewer-login-btn.ant-btn.ant-btn-default.ppl-btn {
    width: 80%;
    //max-width: 500px;
    min-width:500px;
    height:73px;
    box-sizing: content-box;
    padding:0px;
    background: #6a29ff;
    border: none;
    color: #fff;
    cursor: pointer;
   }
   .viewer-login-btn.ant-btn.ant-btn-default.ppl-btn[disabled],
   .viewer-login-btn.ant-btn.ant-btn-default.ppl-btn[disabled]:hover{
    cursor: not-allowed;
      background: #B9A4E6;
      color: #FFFFFF;
   }
}
.err-modal-btn-warpper{
  .err-modal-btn,
  .err-modal-btn:hover{
    background-color: #6435c9;
    color: #f4f4f4;
    font-weight: 700;
    border-radius: 8px;
  }
}
