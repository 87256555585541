@import "src/variables";
.formWrap {
  margin: 3rem auto 0;
}
.formRow {
  input,
  &.checkoutInput select {
    display: block;
    width: 100%;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 400;
    text-align: left;
    padding: 10px 5px;
    margin: 10px auto;
    border: 1px solid #9e9e9e;
    outline: none;
  }
}

.left-side-signup-content {
  background: transparent url("../../../assets/images/signup_bg.png") 60% 100%
    no-repeat padding-box;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  flex: 2;
  .logo {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.right-side-signup-content {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-btn-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  .register-btn,
  .register-btn:hover,
  .register-btn:active {
    box-sizing: content-box;
    padding: 14px;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    border-radius: $border-radius-md;
    background: #6a29ff;
    border: none;
    color: #fff;
    cursor: pointer;
  }
  .ant-btn-default[disabled],
  .ant-btn-default[disabled]:hover {
    cursor: not-allowed;
    background: #b9a4e6;
    color: #ffffff;
  }
  .signup-err {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
.registerForm-container {
  max-width: 624px;
  margin: auto;
  width: 80%;
  .ant-form-item-explain-error {
    height: 10px;
    color: red;
    opacity: 1;
  }
  .ant-input-status-error {
    border: 1px solid red;
  }
  .ant-input-affix-wrapper.ant-input-password.password-input.ant-input-affix-wrapper-status-error {
    border: 1px solid red;
    .ant-input {
      background-color: #ffffff;
    }
  }
  .ant-input-password-status-error {
    border: none;
    border: 1px solid red;
  }
  .ant-input-affix-wrapper.ant-input-password.password-input.ant-input-affix-wrapper-status-success {
    background-color: #f7f8fe;
    .ant-input {
      background-color: #f7f8fe;
    }
  }
  h3.ant-typography {
    font-weight: 700;
  }
  .terms-conditions-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .terms-text {
      color: #00000080;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      a {
        color: #00000080;
        font-weight: 700;
      }
    }
    .terms-check-box {
      color: #000000;
      font-size: 12px;
      font-weight: 400;

      text-align: left;
    }
    
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    box-shadow: inset 0 0 0 1px;
    -webkit-box-shadow: 0 0 0px 1000px #f7f8fe inset;
    transition: background-color 600000s 0s, color 600000s 0s;
    //font-size: 20px;
    input:-webkit-autofill::first-line {
      font-size: 20px;
    }
  }
}

.signup-name-row {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.signup-name-row > div {
  flex: 1;
  .passwordwith-tooltip {
    width: 300px;
  }
}

.signup-title {
  display: flex;
  justify-content: space-between;
}

.password-info-button-container {
  max-width: 46px;
  max-height: 122px;

  align-items: center;
  .password-info-button {
    height: 68px;
    margin-top: 30px;
    background-color: #f7f8fe;
    border: none;
    border-radius: 8px;
    color: #00000080;
  }
}

.input-icon {
  display:flex;
  flex-direction:column;
  label{
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.15em;
    padding-bottom: 8px;
  }
}
.terms-conditions-modal{
  .ant-modal{
    width:800px;
  }
.ant-modal-footer{
  display:none;
}
.ant-modal-header{
  text-align:center;
  border-radius: 8px;
  //width:800px;
  background: #F7F8FE;
  
}
.modal-footer{
  text-align:center;
  .modal-button{
    height:50px;
    width:300px;
    border-radius:8px;
    background-color:#6a29ff;
    color:#ffff;
    font-weight: 700;
  }
}
.ant-modal-body{
  min-height: 570px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  border-radius: 8px;
  //width:800px;
}
.ant-modal-content {
  
  width:100%;
  background: #F7F8FE;
  margin-right:300px;
}
.ant-modal-close-x {
 display:none;
}
  
}