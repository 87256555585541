@import 'src/variables';
.right-content {
    display: flex;
    flex-direction: column;
    background: #F7F8FE;
    flex: 1;
    flex-direction: column;
    padding: 3.33% 6.67%;
    gap: 18px;

    .header-menu {
        display:flex;
        justify-content: flex-end;
       position:absolute;
        top:10px;
        right:10px;
       // margin-top:20px;
       // margin-right:20px;
        
    }

    .top-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0px;
        .header-wrapper{
            margin-left:0px;
            .title-component{
                margin-bottom:0px;
            }
        }
        .title-component {
            margin: 0px;
        }

        .back-button {
            border: none;
            padding-left: 0px;
            background: none;
            cursor: pointer;
        }
        
    }

    .bottom-section {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap:5px;
        .title-component {
            margin-bottom: 18px;
        }
    }

    .ant-divider-horizontal {
        margin: 0;
    }
}

.top-content-wrapper {
    display: flex;
    justify-content: space-between;

    .no-of-rooms-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .title-component {
            margin: 0;
        }

        input[type="number"] {
            width: 194px;
        }
    }

    .no-of-orders-wrapper {
        .title-component {
            margin-bottom: 44px !important;
        }
    }

    input,
    textarea {
        border: none;
        box-sizing: border-box;
        margin: 0;
        outline: none;
        padding: 10px;
        background-color: #ffffff;
    }

    input[type="button"] {
        -webkit-appearance: button;
        cursor: pointer;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .input-group {
        display:flex;
        //clear: both;
        //position: relative;
        height: 68px;
        font-size: 24px;
        color: #000000;

        input[type="text"], input[type="number"] {
            color:$ppl-color-black ;
        }
    }

    .input-group input[type='button'] {
        background-color: #ffffff;
        min-width: 38px;
        width: auto;
        transition: all 300ms ease;
    }

    .input-group .button-minus {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .input-group .button-plus {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .input-group .button-minus,
    .input-group .button-plus {
        font-weight: 700;
        height: 68px;
        padding: 0;
        // width: 38px;
        position: relative;
    }

    .input-group .button-minus.disabled,
    .input-group .button-plus.disabled {
        color: rgba(0, 0, 0, 0.16);
        cursor: not-allowed;
    }
  

    .input-group .quantity-field {
        position: relative;
        height: 68px;
        left: -6px;
        text-align: center;
        width: 62px;
        display: inline-block;
        margin: 0 0 5px;
        resize: vertical;
        .inactive{
            color: #808080;
        }
    }

    .button-plus {
        left: -13px;
    }

    input[type="number"] {
        -moz-appearance: textfield;
        -webkit-appearance: none;
    }

}

.order-resume-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 240px;

    .bold {
        color: #000000;
        font-weight: 700;
    }

    .total-amount {
        font-size: 20px;
    }

    .inactive{
        color:#808080;
        font-size: 20px;
    }
    

    .order-resume-row {
        display: flex;
        justify-content: space-between;
    }
}

.reactivation-message{
    color:#ED1C24;
}