@import 'src/variables';
$layout-padding:80px 4px;
.formWrap {
  margin: 3rem auto 0;
  max-width: 60rem;
}
.formRow {

  input,
  &.checkoutInput select {
    display: block;
    width: 100%;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 400;
    text-align: left;
    padding: 10px 5px;
    margin: 10px auto;
    border: 1px solid #9e9e9e;
    outline: none;
  }
}
.left-content {
  background: transparent url('../../../assets/images/login_bg.png') 60% 100% no-repeat padding-box;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  flex: 1;

  .logo {

      margin-top: 20px;
      margin-left: 20px;
  }
}

right-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;

}
.need-support-profile {
  margin-top: -60px;
  margin-left: calc(100% - 100px);
  width:100px;
}

.need-support-copy {
  margin-top: -60px;
  margin-left: calc(100% - 312px);
}
.form-input-wrapper {
  // padding: 80px 48px 48px;
  display: flex;
  justify-content: space-between;

  div {
    flex:1;
    margin:1px;
  }

  .input-field {
    // width: fit-content;
    flex: 1;
    background-color: $ppl-white;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus{
     box-shadow: inset 0 0 0 1px;
      -webkit-box-shadow: 0 0 0px 1000px $ppl-white inset;
       transition: background-color 600000s 0s, color 600000s 0s;
       //font-size: 20px;
       input:-webkit-autofill::first-line {
          font-size: 20px;
        }
       
      }   
  
}

.form-wrapper {
  div {
    flex:1;
    margin:1px;
  }
  .input-field {
    background-color: $ppl-white;
  }
  
}
.account-settings-footer {
    // position: absolute;
    // bottom: 30px;
    // right: 30px;
    flex-direction: row;
    padding: $layout-padding;
    // flex: 1.5;
    justify-content: space-between;
    display: flex;
    flex-grow: 1;
    .account-settings-btn,
    .account-settings-btn:hover {
        box-sizing: content-box;
        padding: 10px 40px;
        font-size: 20px;
        font-weight: 600;
        border-radius: 5px;
        background: #6A29FF;
        border: none;
        color: #fff;
        cursor: pointer;
        width: 100%;
        height:40px;
    }
    .account-settings-btn[disabled],
    .account-settings-btn[disabled]:hover
    {
      cursor: not-allowed;
      background: #B9A4E6;
      color: #FFFFFF;
     
  }
    .account-settings-btn-cancel,
    .account-settings-btn-cancel:hover{
      box-sizing: content-box;
      padding: 10px 40px;
      font-size: 20px;
      font-weight: 600;
      border-radius: 5px;
      background: transparent;
      border: none;
      color: #6A29FF;
      border:1px solid #6A29FF;
      cursor: pointer;
      width: 100%;
      height:40px;
  }
}
