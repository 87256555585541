@import "src/variables";

.socialSignin {
  margin: 0.5rem auto 0;
  .row {
    margin: 0.5rem auto 0;
  }
}

.links {
  display: block;
  width: 100%;
  margin: 1.5rem auto 0;

  a {
    color: black;
  }
}

.twoFA-left-content {
  background: transparent url("../../../assets/images/login_bg.png") 60% 100%
    no-repeat padding-box;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  flex: 2;

  .logo {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.twoFA-right-content {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 44px;
  align-items: center;
  background: #f7f8fe;
  .twoFA-form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 30px;
    margin-left: 88px;
  }
  .ant-switch-checked {
    background: #34a853;
  }
  .switch-wrapper {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 14px;
  }
}
.twoFA-container {
  margin-left: 11%;
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 624px;
  height: 677px;
  .switch-title {
    font-size: 14px;
    font-weight: 700;
  }
  .ant-input {
    background-color: #ffff;
  }
  .ant-input-affix-wrapper.ant-input-password.password-input.ant-input-affix-wrapper {
    background-color: #ffff;
  }
  .ant-form-item-explain-error {
    font-size: 14px;
  }
  .ant-input-affix-wrapper.ant-input-password.password-input.ant-input-affix-wrapper-status-error {
    border: 1px solid red;
    .ant-input {
      background-color: #ffffff;
    }
  }
  .ant-input-password-status-error {
    border: 1px solid red;
  }
}

.twoFA-switch {
  background: green;
}
.twoFAmid {
  height: 400px;
  width: 624px;
  display: flex;
  flex-direction: column;
  padding-top:50px;
  .qrcode{
    padding-left:0px;
    padding-top:30px;
    padding-bottom:30px;
    width:180px;
    height:180px;
    display:flex;
    justify-content: left;
    align-items:center;
  }
  .passcode-wrap {
    width: 300px;
  }
  .twoFA-msg {
    border: 1px solid#42B729;
    background: #e5f2e9;
    border-radius: 8px;
    height: 47px;
    width: 204px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
  }
  .twoFA-msg-blank{
    height: 47px;
    width: 204px;
  }
}
.twoFAbottom {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 24px;
  margin-top: 18px;
  .twoFA-verify-btn,
  .twoFA-verify-btn:hover,
  .twoFA-verify-btn:active {
    width: 300px;
    height: 68px;
    border-radius: 8px;
    background-color: #6a29ff;
    color: #ffff;
    font-weight: 700;
    font-size: 24px;
  }
  .twoFA-verify-btn[disabled],
  .twoFA-verify-btn[disabled]:hover{
    cursor: not-allowed;
    background: #b9a4e6;
    color: #ffffff;
  }
  .twoFA-cancel-btn,
  .twoFA-cancel-btn:hover,
  .twoFA-cancel-btn:active {
    width: 300px;
    height: 68px;
    border: 1px solid #6a29ff;
    border-radius: 8px;
    color: #6a29ff;
    background-color: #ffff;
    font-weight: 700;
    font-size: 24px;
  }
}
.twoFA-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}
.twoFAmid-center{
  display:flex;
  flex-direction:row;
  .twoFAmid-center-left{
    width:312px;
  }
  .twoFAmid-center-right{
    display:flex;
    justify-content:baseline;
    
    padding-left:50px;
    margin-bottom:50px;
    width:312px;
  }
}
.twoFA-layout {
  width: 100%;
  .ctc-container,
  .ant-input {
    background: #ffffff;
  }

  .ant-layout-header {
    background: #ffffff;
  }

  .ant-tabs {
    min-height: 100%;
  }

  .ant-tabs-nav {
    height: 76px;
    background-color: #ffffff;
    margin: 0 !important;
    border: none !important;
  }

  .ant-tabs-tab {
    display: flex;
    width: 76px;
    justify-content: center;
    align-items: center;
    //border-bottom: 4px solid #EDEDED;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab-active {
    border-color: #ffff;
  }

  .tab-icon {
    width: 24px;
    height: 24px;
  }

  .ant-tabs-ink-bar{
    background: #f7f8fe;
  }
  .ant-tabs-content-holder {
    background: #f7f8fe;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 76px);
  }

  .ant-tabs-content {
    flex-grow: 1;
  }

  .user-avatar {
    background-color: "#f56a00";
    vertical-align: "middle";
    margin-right: 20px;
    cursor: pointer;
  }
}
