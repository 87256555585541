@import 'src/variables';

.socialSignin {
  margin: 0.5rem auto 0;
  .row {
    margin: 0.5rem auto 0;
  }
}

.links {
  display: block;
  width: 100%;
  margin: 1.5rem auto 0;

  a {
    color: black;
  }
}

.left-content {
  background: transparent url('../../../assets/images/login_bg.png') 60% 100%
    no-repeat padding-box;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  flex: 1;

  .logo {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.signin-right-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

.forgot-container {
  display: flex;
  flex-direction: column;
  width: 500px;
  .ant-form-item-explain-error {
    font-size: 14px;
    color:red;
    opacity:1;
  }
  .ant-input-status-error {
    border: 1px solid red;
  }
}

.forgot-title {
  display: flex;
  justify-content: space-between;
}
.button-box {
  display: flex;
  justify-content: flex-end;
  .getPassword-btn,
  .getPassword-btn:active,
  .getPassword-btn:hover {
    width: 234px;
    height: 73px;
    padding: $spacing-lg;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    border-radius: $border-radius-md;
    background: #6a29ff;
    border: none;
    color: #fff;
  }
  .ant-btn-default[disabled],
  .ant-btn-default[disabled]:hover {
    cursor: not-allowed;
    background: #b9a4e6;
    color: #ffffff;
  }
}
.forgot-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.need-support-forgot {
  margin-top: -220px;
  margin-left: 405px;
}
.need-support-copy-forgot-password {
  margin-top: -220px;
  margin-left: calc(100% - 312px);
}