.ui.inverted.dimmer .ui.loader:before {
  border-color: #6600ff;
}
.ui.inverted.dimmer {
  background-color: rgba(45,45,45,.85) !important;
}
.ui.inverted.dimmer .ui.loader:after {
  border-color: #ffffff transparent transparent;
}

.ui.massive.loader:after, .ui.massive.loader:before {
  width: 6rem !important;
  height: 6rem !important;
}
