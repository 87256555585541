.wallet {
  display: block;
  width: 100%;
  max-width: 50rem;
  padding: 0;
  margin: 2rem auto;

  .newcard {
    margin-top: 40px;
    .setup {
      padding: 5px;
      margin: 10px;
    }
  }

  .list-saved-card {
    p {
      text-align: center;
    }
  }
}
.heading-center-aligned {
  text-align: center;
}
