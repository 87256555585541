.rooms-layout {
    .banner-container {
        margin-top:10px;
    }
    .ctc-container,
    .ant-input {
        background: #ffffff;
    }
    .adv-setting ,.adv-setting:hover {
        color: #000000 !important;
        font-size: 17px;
        background: none !important;
        font-weight: 700;
        font-family: "Nunito Sans", "sans-serif" !important;
        border: none !important;
        justify-content: flex-start; /* Aligns items to the left */
        align-items: center;
        min-width: 330px;
        display:flex;
        flex-direction:row;
        width: 100%;
      }
    .ant-layout-header {
        background: #ffffff;
    }
   

    .ant-tabs {
        min-height: 100%;
    }

    .ant-tabs-nav {
        height: 76px;
        margin: 0 !important;
        border: none !important;
    }

    .ant-tabs-tab {
        display: flex;
        width: 76px;
        justify-content: center;
        align-items: center;
        border-bottom: 4px solid #EDEDED;
    }

    .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
    }

    .ant-tabs-tab-active {
        border-color: #6A29FF;
    }

    .tab-icon {
        width: 24px;
        height: 24px;
    }

    .ant-tabs-content-holder {
        background: #F7F8FE;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: calc(100vh - 76px);

    }

    .ant-tabs-content {
        flex-grow: 1;
    }

    .user-avatar {
        background-color: '#f56a00';
        vertical-align: 'middle';
        margin-right: 20px;
        cursor: pointer;
    }

    .tab1-wrapper {
        height: 100%;
        padding: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 63px;
        
    }

    .create-room-content {
        .create-room-row1 {
            display: flex;
            gap: 30px;
            margin-bottom: 30px;
            label{
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.15em;
                padding-bottom: 8px;
                span{
                    color: #E8002E;
                }
            }
            .ant-input{
                margin-top:8px;
            }
            .ant-input-error{
                border:2px solid red;
            }
            .err-msg-wrapper{
                height:22px;
            }
        }

        .create-room-row1 div {
            flex: 1 0 47%;
        }
        .select-waiting-wrapper-checkbox {
            background: white;
            height: 68px;
            display: block;
            padding: 8px;
            margin-top: 30px;
            border-radius: 8px;
            border: 2px solid white;
        }
        .inline-waiting-room {
            margin: 5px;
        }
        // .create-room-row2{
        //     margin-bottom: 30px;

        .stream-end-date {
            // width: 47%;

            .ant-picker {
                width: 100%;
                border: none;
                border-radius: 8px;

                .ant-picker-input {
                    height: 60px;
                    display: flex;
                    flex-direction: row-reverse;

                    input {
                        padding-left: 16px;
                        font-size: 20px;
                        line-height: 44px;
                        border-left: 1px solid #DADBE4;
                    }
                    input[disabled]{
                        color:#8d8f9c;
                        font-size: 20px;
                    }

                    .ant-picker-suffix {
                        width: 68px;
                        margin: 0;
                        color: #000000;
                        font-size: 20px;
                        display: flex;
                        justify-content: center;

                    }
                }
            }

            // }
        }

        .select-countries-wrapper {
            .countries-dropdown.ant-select.ant-select-multiple .ant-select-selector{
                border: none !important;
                border-radius: 8px !important;
                min-height: 68px;
                height: auto !important;
            }
            .form-input-label {
                display: flex;
                gap: 12px;
                align-items: center;
            }

            .support-text {
                letter-spacing: 0;
            }

            .ant-select-selector {
                background-color: #ffffff !important;
                font-size: 20px;
            }
            .ant-select-disabled.ant-select-multiple .ant-select-selection-item{
                color:#8d8f9c;
            }
            .ant-select-disabled{
                .ant-select-arrow{
                    display:none;
                }
            }

        }
        .select-waiting-wrapper {
            .form-input-label {
                // display: inline;
            }
            .switch {
                margin: 15px;
            }
        }

    }

    .host-details-wrapper {

        .host-details-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .title-wrapper{
                min-width: 330px;
                display:flex;
                flex-direction:row;
                gap:20px;
                .copy-btn {
                    border:none;
                    color:#7E7C7F;
                    background:#F7F8FE;
                    margin-top:-4px;
                    border-top:0px;
                    border-bottom:0px;
                    height:24px;
                    cursor:pointer;
                    .copy-icon{
                        width:20px;
                        height:24px;
                    }
                }
            }
            .refresh-btn-wrapper{
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap:10px;
                .refresh-btn {
                    width: 230px;
                    height: 46px;
                    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #EDEEF4;
                    border-radius: 8px;
                    color: #6A29FF;
                    border: none;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                    padding: 12px;
                }
    
                .refresh-btn.disabled {
                    background: #EDEEF4;
                    color: #CCCCCC;
                }
                .navigate-viewer{
                    width: 230px;
                    height: 46px;
                    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #EDEEF4;
                    border-radius: 8px;
                    color: #6A29FF;
                    border: none;
                    font-weight: 700; 
                    font-size: 16px;
                    line-height: 22px;
                    padding: 12px;
                }
            }
            
        }

        .host-details-content {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            .ppl-btn .delete{
                background: #E8002E;
            }
        }

        .host-details-content div {
            flex: 1 0 47%;
        }
    }


    .actions-wrapper {
        display: flex;
        flex-direction: row-reverse;
        gap: 24px;
 
        
    }
}

.ant-dropdown-menu {
    width: 170px;
    border-radius: 8px;
    text-align: end;
}

.ant-dropdown-arrow {
    display: none !important;
}

.copy-modal{
    .ant-modal-content{
        border-radius: 8px;
    }
    .ant-modal-header{
        border-radius: 8px;
        height:50px;
    }
    .ant-modal-body{
        background-color: #EDECEC;
        border-radius: 8px;
        height:300px;
    }
    .ant-modal-footer{
        display:flex;
        flex-direction: row-reverse;
        gap:15px;
        .ant-btn-primary{
            background-color: #0D6FE9;
        }
    }
}
.upgrade-modal{
    padding:70px;
    .ant-modal-content {
        display: flex;
        border-radius: 24px;
    }
    
    .ant-modal-body{
        padding:0px;
        border-radius: 10px;
    }
    .logoWrapper{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 30px 0px 30px 30px;
        .close-btn{
            border:none;
            background: #ffffff;
            cursor: pointer;
            margin-left: 500px;
        }
    }
    .modal-badge{
        background-color:#E8002E;
        color:#ffff;
        height:45px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .modal-text{
        margin-top:60px;
        padding: 0px 222px 0px 52px;
        .modal-text-title{
            font-weight: 700;
            font-size:24px;
        }
        .modal-text-content{
           
            font-size: 14px;
            padding-top:24px;
        }
       
    }
    .continue-btn-wrapper{
     margin: 123px 0px 0px 54px;
     padding:0px;
     display:flex;
     flex-direction:row;
     justify-content: space-between;
    .continue-btn,
    .continue-btn:hover{
        width:160px;
        height:49px;
        background-color: #6A29FF;
        color: #FFFFFF;
        font-size:18px;
        font-weight:700;
        border-radius:8px;
        margin-right:40px;
    }  
    .cancel-btn,
    .cancel-btn:hover{
        width:158px;
        height:47px;
        color: #6A29FF;
        background-color: #FFFFFF;
        font-size:18px;
        font-weight:700;
        border-radius:8px;
        border:1px solid #6A29FF;
    }  
    }
  }
  .ppl-btn.delete {
    color: #ffffff !important;
    background: #f0676f !important;
    border: 1px solid #f0676f;
    width: 250px;
}

.ppl-btn.delete:hover {
    color: #ffffff !important;
    background: #f0676f !important;
    border: 1px solid #f0676f;
    width: 250px;
}