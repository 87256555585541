.header {
  height: 6.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .wrap {
    position: relative;
    height: 100%;
    max-width: 1450px;
    margin: 0 auto;

    .logo {
      width: 15rem;
      position: absolute;
      top: 55%;
      left: 10px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%);

      img {
        display: block;
        width: 100%;
        margin: 0;
      }
    }

    nav {
      display: block;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;

      ul,
      ul li {
        padding: 0;
        margin: 0;
        height: 100%;
      }

      ul {
        text-align: center;

        li {
          display: inline-block;
          margin: 0 1rem;
          list-style-type: none;

          a {
            font-size: 1.8rem;
            line-height: 6.5rem;
            vertical-align: middle;
            color: black;
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
      }
    }

    .callToActions {
      position: absolute;
      top: 50%;
      right: 10px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%);

      i {
        display: none;
      }

      ul,
      li {
        margin: 0;
        padding: 0;
      }

      li {
        display: inline-block;
        list-style-type: none;
        margin-right: 1.5rem;

        &:last-child {
          margin-right: 0;
        }

        a,
        span {
          font-size: 1.8rem;
          line-height: 1;
          color: black;
          text-decoration: none;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
    }
  }
}

.mobileMenu {
  display: none;
}

@media only screen and (max-width: 980px) {
  .mobileMenu {
    display: block;
  }

  .header .wrap {
    .callToActions {
      i {
        display: inline-block;
      }

      ul li {
        &.hideOnMobile {
          display: none;
        }

        a,
        span {
          font-size: 0;

          i {
            font-size: 1.8rem;
          }
        }
      }
    }

    .mainMenu {
      display: none;
      position: absolute;
      height: auto;
      top: 100%;
      left: 0;
      z-index: 10;
      background-color: white;
      border-bottom: 1px solid lightgray;

      &.active {
        display: block;
      }

      ul li {
        width: 100%;
        margin: 0;
        border-top: 1px solid lightgray;

        a {
          display: block;
          width: 100%;
          text-align: left;
          padding: 0 10px;
        }
      }
    }
  }
}
