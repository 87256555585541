@import 'src/variables';

.socialSignin {
    margin: 0.5rem auto 0;

    .row {
        margin: 0.5rem auto 0;
    }
}

.links {
    display: block;
    width: 100%;
    margin: 1.5rem auto 0;

    a {
        color: black;
    }
}

.left-content {
    background: transparent url('../../../assets/images/login_bg.png') 60% 100% no-repeat padding-box;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    flex: 1;

    .logo {

        margin-top: 20px;
        margin-left: 20px;
    }
}

.signin-right-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
}

.login-btn-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .sign-in-err {
        display: flex;
        justify-content: center;
      }

    .login-btn,
    .login-btn:hover,
    .login-btn:active {
        box-sizing: content-box;
        padding: $spacing-lg;
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
        border-radius: $border-radius-md;
        background: #6A29FF ;//!important;
        border: none;
        color: #fff;
        cursor: pointer;
        width: 476px;
        height: 49px;
    }
    .ant-btn-default[disabled],
    .ant-btn-default[disabled]:hover {
      cursor: not-allowed;
      background: #B9A4E6;
      color: #FFFFFF;
     
    }
}

.loginForm-container {
    min-width: 500px;
    padding: 0px 50px 0px 50px;
    .ant-form-item-explain-error {
        font-size: 14px;
        color:red;
        opacity:1;

    }

    .ant-input-status-error {
        border: 1px solid red;
    }

    .ant-input-affix-wrapper.ant-input-password.password-input.ant-input-affix-wrapper-status-error {
        border: 1px solid red;
        .ant-input{
            background-color: #ffffff;
        }
    }

    .ant-input-affix-wrapper.ant-input-password.password-input.ant-input-affix-wrapper-status-success{
        background-color: #F7F8FE;
        .ant-input{
            background-color: #F7F8FE;
        }
    }
    
    .ant-input-password-status-error {
        border: none;
        border: 1px solid red;
     
    }

    h3.ant-typography {
        font-weight: 700;
    }
    .ant-input-status-success{
        background-color: #F7F8FE;        
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus{
        box-shadow: inset 0 0 0 1px;
        -webkit-box-shadow: 0 0 0px 1000px #f7f8fe inset;
        transition: background-color 600000s 0s, color 600000s 0s;
        font-size: 20px;
        &:-webkit-autofill::first-line {
            font-size: 20px;
        }
    }   
}

.rememberme-forgotpwd {
    display: flex;
    margin: 20px auto;
}

.rememberme-wrapper {
    width: 50%;
}

.forgotpwd-wrapper {
    width: 50%;
    text-align: right;
}

.required{
    color:#FF0000;
}

.custum-label{
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.15em;
    color: #000000;
    padding-bottom: 8px;
}