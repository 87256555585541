.heading-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 40px; /* Adjust the size as needed */
    height: 100vh;
}

