.timer-ui {
  height: 56px;
  background-color: #fffbe6;
  border-radius: 8px;
  padding: 10px;
}
.timer-ui-empty{
  height:56px;
  background-color: #000;
}
