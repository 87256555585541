@import "src/variables";

.socialSignin {
  margin: 0.5rem auto 0;

  .row {
    margin: 0.5rem auto 0;
  }
}

.links {
  display: block;
  width: 100%;
  margin: 1.5rem auto 0;

  a {
    color: black;
  }
}

.left-content {
  background: transparent url("../../../assets/images/login_bg.png") 60% 100%
    no-repeat padding-box;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  flex: 1;

  .logo {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.signin-right-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;

  .login-form-container {
    width:500px;
    .btn-container {
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      align-items:flex-end;
      height:300px;
      width:auto;
      gap:20px;
      .login-btn-container {
        
        display: flex;
        flex-direction: column;
       // gap: 4px;
        .sign-in-err {
          display: flex;
          justify-content: center;
        }

        .login-btn,
        .login-btn:hover,
        .login-btn:active {
          box-sizing: content-box;
          padding: $spacing-lg;
          font-size: $font-size-xl;
          font-weight: $font-weight-bold;
          border-radius: $border-radius-md;
          background: #6a29ff; //!important;
          border: none;
          color: #fff;
          cursor: pointer;
        width: 216px;
          height: 49px;
        }
        .ant-btn-default[disabled],
        .ant-btn-default[disabled]:hover {
          cursor: not-allowed;
          background: #b9a4e6;
          color: #ffffff;
        }
        
      }
      .otp-footer-wrapper1 {
        //flex-direction: row;
        // padding: 20px 4px;
         // flex: 1.5;
         //justify-content: space-between;
         //display: flex;
         //flex-grow: 1;
        //width:auto;
        
         .otp-btn {
           box-sizing: content-box;
           padding: $spacing-lg;
           font-size: $font-size-xl;
           font-weight: $font-weight-bold;
           border-radius: $border-radius-md;
           background: #6a29ff; //!important;
           border: none;
           color: #fff;
           cursor: pointer;
           width:216px;
           height: 49px;
         }
         .otp-btn-disabled {
           box-sizing: content-box;
           padding: $spacing-lg;
           font-size: $font-size-xl;
           font-weight: $font-weight-bold;
           border-radius: $border-radius-md;
           background: #6a29ff; //!important;
           border: none;
           color: #fff;
           cursor: pointer;
            width:216px;
           height: 49px;
         }
       }
    }
  }
}

.user_no_image {
  background: #fff url("../../../assets/images/logo_dark.png") no-repeat
    padding-box;
  // background: #fff url('../../../src/assets/images/logodark.png') 0 0 no-repeat padding-box;
  background-size: contain;
  background-position: 50%;
  height: 98px;
  width: 98px;
  top: -30px;
}
.user_no_image,
.user_list_image {
  border: 2px solid #000000;
  border-radius: 50%;
  opacity: 1;
}
.user_image,
.user_list_image {
  margin: 0 auto;
  border: 2px solid #000000;
  border-radius: 50%;
  opacity: 1;
}

.user_image {
  height: 98px;
  width: 98px;
  top: -30px;
}
.header-wrapper {
  margin: 0 auto;
  .title-wrapper {
    margin-top: 10px;
    text-align: center;
  }
}
p {
  text-align: center;
  font-size:17px;
}
.need-support-emailOtp {
  width: 100%;
  margin-left: calc(100% - 100px);
}
.need-support-copy-email-otp{
  margin-left: calc( 100% - 312px);
}