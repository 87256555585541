@import 'src/variables';

.socialSignin {
    margin: 0.5rem auto 0;
    .row {
        margin: 0.5rem auto 0;
    }
}

.links {
    display: block;
    width: 100%;
    margin: 1.5rem auto 0;

    a {
        color: black;
    }
}

.resetpassword-left-content {
    background: transparent url('../../../assets/images/login_bg.png') 60% 100%
        no-repeat padding-box;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    flex: 1;

    .logo {
        
        margin-top: 0px;
        margin-left:0px;
    }
}

.resetpassword-right-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;

    
}
.resetpassword-container{
    display:flex;
    flex-direction:column;
    width:500px;
    .ant-form-item-explain-error{
        font-size: 14px;
        color:red;
        opacity:1;
       
    }
    .ant-input-affix-wrapper.ant-input-password.password-input.ant-input-affix-wrapper-status-error {
        border: 1px solid red;
        .ant-input{
            background-color: #ffffff;
        }
    }
    .ant-input-password-status-error{
        border:1px solid red;
    }
    .ant-input-affix-wrapper.ant-input-password.password-input.ant-input-affix-wrapper-status-success{
        background-color: #F7F8FE;
        .ant-input{
            background-color: #F7F8FE;
        }
    }
}
.space{
    width:500px;
    height:181px;
    border:1px solid #42B729;
    background-color: #E5F2E9;
    border-radius: 8px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    font-size:14px;
   
}
.resetpassword-btn-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    display:flex;
    justify-content:flex-end;
    .resetpassword-btn,
    .resetpassword-btn:active,
    .resetpassword-btn:hover{
        width:500px;
        height:73px;
        padding: $spacing-lg;
            font-size: $font-size-xl;
            font-weight: $font-weight-bold;
            border-radius: $border-radius-md;
            background: #6A29FF;
            border: none;
            color: #fff;
    }
    .ant-btn-default[disabled],
    .ant-btn-default[disabled]:hover {
      cursor: not-allowed;
      background: #B9A4E6;
      color: #FFFFFF;
     
    }

   

       
    }

.bottom-space{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    gap:54px;
}
.list-head{
  font-weight:700;
  margin-left:44px;
  font-size:14px;
}
.list-data{
    font-weight:400;
    margin-left:44px;
    margin-top:14px;
  font-size:14px; 
  display:flex;
  flex-direction:column;
}
.need-support-reset {
    width: 100%;
    margin-left: calc(100% - 100px);
}
.need-support-copy-reset {
    width: 100%;
    margin-left: calc(100% - 312px);
}
