@import 'src/variables';

.payment-method-modal {
    
    .ant-modal-content {
        display: flex;
        border-radius: 10px;
    }

    .ant-modal-body{
        min-height: 570px;
        background: #F7F8FE;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;

        .add-payment-methods-wrapper{
            display: flex;
            gap: 16px;
            
            .payment-method-icon {
                border: none;
                padding: 0;
            }
        }
        .payment-method-page-wrapper {
            padding: 80px 48px 48px;
            .spacer-lg {
                margin:$spacing-xxl
            }
        }
        
    }
    
    .ant-modal-header, .ant-modal-footer {
        display: none;
    }
    
    .ant-modal-close-x {
        padding-right: 46px;
        padding-top: 18px;
    }
    .ant-modal-close-icon {
            // padding: 46px;
    }
    border-radius: 10px;
    width: 60% !important;
    .add-payment-method-footer {
        position: absolute;
        bottom: 30px;
        right: 30px;
        .btn-continue {
            box-sizing: content-box;
            padding: 10px 40px;
            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
            border-radius: $border-radius-md;
            background: #6A29FF;
            border: none;
            color: #fff;
            cursor: pointer;
        }
    }
    .add-payment-method-header {
        display: flex;
        justify-content: flex-end;
        .modal-close-btn {
            color: #C1C4D5;
           
        }
    }

}

.add-payment-method-button {
    width: 197px !important;
    height: 62px !important;
    background: #FFFFFF !important;
    border-radius: 8px !important;
}
.add-payment-method-button:hover {
    background: #FFFFFF !important;
    color: rgba(0, 0, 0, 0.85);
}
