
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap'); 

*:not(i) {
  font-family: 'Nunito Sans', sans-serif !important;
}

.show-new-viewer {
  background: transparent url("../../pages/Viewer/Images/video_bg.png") 0% 0% no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.show-new-viewer .chat-icon {
  background-image: url("../../pages/Viewer/Images/chat_icon.svg") !important;
  background-repeat: no-repeat;
  background-size: 20px 18px !important;
  background-position: 10px !important;
}

.show-new-viewer .cross-icon {
  background-image: url("../../pages/Viewer/Images/cross_icon.svg") !important;
  background-repeat: no-repeat;
  background-size: 25px 13px !important;
  background-position: 10px !important;
  bottom: 5px;
}

.show-new-viewer .ui.input>input {
  background: #3d3d3d;
  border: 1px solid white;
  color: white;
}

.show-new-viewer .fa-2x {
  font-size: 2em;
}

.show-new-viewer .fa {
  position: relative;
  display: table-cell;
  width: 56px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
}

.show-new-viewer .main-menu {
  background: #000000;
  position: absolute;
  top: -57px;
  bottom: 0;
  left: 0;
  width: 56px;
  overflow: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.show-new-viewer .main-menu-right {
  background: #000000;
  position: fixed;
  top: 0px;
  bottom: 0;
  right: 0;
  float: right;
  width: 56px;
  overflow: hidden;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 900;
}

.show-new-viewer .main-menu-right-chat {
  background: #141414;
  color: white;
  display: flex;
  flex-direction: column;
  -webkit-transition: width 0.05s linear;
  transition: all 0.5s;
  height: 100%;
  width: 100%;
  min-width: 360px;
  position: relative;
  // z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
}

.show-new-viewer .main-menu-right-chat::-webkit-scrollbar {
  height: 12px !important;
  width: 12px !important;
  background: #000 !important;
}

.show-new-viewer .main-menu-right-chat::-webkit-scrollbar-thumb {
  background: #1b1b1b !important;
  -webkit-border-radius: 1ex !important;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75) !important;
}

.show-new-viewer .main-menu-right-chat::-webkit-scrollbar-corner {
  background: #000 !important;
}

.show-new-viewer .main-menu-right-chat-users {
  background: black;
  position: absolute;
  top: 56px;
  right: 0;
  margin-right: 0px;
  color: white;
  float: right;
  width: 344px;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
  padding-left: 20px;
}

.show-new-viewer .chat-h1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1d1d;
}

.show-new-viewer .chat-h2 {
  position: sticky;
  top: 50px;
  margin-top: 10px;
  height: 55px;
  width: 344px;
  background: #1c1d1d;
}

.show-new-viewer .chat-text {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  padding: 20px;
  letter-spacing: 2px;
}
.show-new-viewer .chat-options-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 16px;
}

.show-new-viewer .chat-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.show-new-viewer .chat-list-item:first-child {
  margin-top: auto !important;
}

.show-new-viewer .chat-list-item {
  display: flex;
  padding: 8px 16px;
}
.show-new-viewer .chat-notification {
  width: 100%;
  text-align: center;
  color: #4A4B51;
  font-size: 14px;
  font-weight: 400;
}
.show-new-viewer .chat-local-message {
  justify-content: flex-end;
}

.show-new-viewer .chat-local-message-body {
  background-color: #282829 !important;
}

.show-new-viewer .chat-image {
  background: transparent url("../../pages/Viewer/Images/user.png") 0% 0% no-repeat padding-box;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background-size: cover !important;
  background-position: cover !important;
  border: 1px solid #fff;
  display: inline-table;
  min-width: 42px;
}

.show-new-viewer .chat-message-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  padding-right: 56px;
  min-width: 65%;
  width: max-content;
  max-width: 100%;
  transition: all 0.2s ease-out;
  background-color: #1d1d1d;
  border-radius: 8px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  position: relative;
  flex-direction: column;
}

.show-new-viewer .chat-message-body .local-message {
  background-color: #282829;
}

.show-new-viewer .chat-heading {
  margin: 0;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.show-new-viewer .chat-message-grey-bg {
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  background: #454248;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
}

.show-new-viewer .chat-message-body .duplicate-icon {
  background: transparent url("../../pages/Viewer/Images/copy_white.svg") no-repeat center;
  height: 20px;
  width: 20px;
  margin: 0px;
}
.show-new-viewer .chat-message-body .edit-message-icon {
  background: transparent url("../../pages/Viewer/Images/edit_message.svg") no-repeat center;
  height: 20px;
  width: 20px;
  margin: 0px;
}
.show-new-viewer .annote-chat-message-grey-bg{
  text-align: left;
}

.zoom-viewer-content {
  display: flex;
  height: calc(100vh - 800px);
  flex-direction: column;
}

.zoom-viewer-content.inverse-layout {
  flex-direction: column-reverse;
  margin-top: -250px;
}

.zoom-video-player {
  height: calc(100% - 200px);
}

.zoom-video-tray {
  height: 200px;
}
.show-new-viewer .send-chat-btn{
  padding: inherit !important;
}
.ui.modal .header{
  height: 6.5rem;
  padding: 1.25rem 1.5rem !important;
}
.guest-viewer-url-container .url-container-wrapper{
  gap: 5px;
}