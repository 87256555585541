@import 'src/variables';
.ctc-container {
    display: flex;
    align-items: center;
    background: #f7f8fe;
    border-radius: 8px;
    .ctc-icon {
        border-left: 1px solid $color-neutral;
        // position: absolute;
        font-size: 20px;
        display: inline-flex;
        cursor: pointer;
        color: $color-neutral;
        padding: $spacing-md $spacing-lg;
        margin-top: $spacing-sm;
        transition: all 0.4s;
        svg {
            transition: all 0.4s;
        }
    }
    .ctc-icon:hover {
        border-color: $color-focus;
        svg {
            color: $color-focus;
        }
    }
}

.ant-input-affix-wrapper {
    &.password-input {
        border-radius: $border-radius-md;
        border: none;
        background: $color-primary;
        font-size: $font-size-lg;
        // padding-top: $spacing-md;
        // padding-bottom: $spacing-md;
    }
}


