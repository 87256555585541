.show-new-viewer .centerMenuTop .top-menu-button-item:hover > span {
    height: auto; /* Height auto to adjust based on content */
    background: none;
    position: absolute;
    display: block; /* Ensure block display for proper positioning */
    bottom: -100%;
    font-size: 14px;
    color: #fff;
    padding: 20px 5px; /* Reduced padding for less space around text */
    text-transform: capitalize;
    white-space: nowrap; /* Prevents text from wrapping to keep words together */
    transition: all 0.5s ease; /* Smooth transition effect */
    opacity: 1;
    z-index: 10;
  
  }

  .show-new-viewer .centerMenuTop .top-menu-button-item:hover span {
    bottom: -77px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 1;
    visibility: visible;
  }
  
  .show-new-viewer .centerMenuTop .top-menu-button-item:hover .volumeSpan {
    margin-left: 70px;
  }

  .pulsating-icon {
    width: 30px;
    height: 30px;
    margin-top: 12px;
    background-image: url("../../../../pages/Viewer/Images/guest_whitebg.svg");
    background-size: contain;
    background-repeat: no-repeat;
    animation-name: pulsate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes pulsate {
    0% {
      background-image: url("../../../../pages/Viewer/Images/guest_whitebg.svg");
      transform: scale(1);
    }
    50% {
      background-image: url("../../../../pages/Viewer/Images/guestRed.svg");
      transform: scale(1.2);
    }
    100% {
      background-image: url("../../../../pages/Viewer/Images/guest_whitebg.svg");
      transform: scale(1);
    }
  }
  