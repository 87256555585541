.left-room-content {
    flex: 4;
}

.right-room-content {
    flex: 7;
}
.expired_room {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .Logout-btn{
        color:#6A29FF;
        font-size: 16px;
        position: fixed;
        top: 20px;
        right: 40px;
    }
    .Logout-btn:hover {
        text-decoration: underline; 
        cursor: pointer;
    }
}
