.unsupported-browser-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    background-color: black;
    color:#FFFFFF;
    .unsupported-browser-page-heading {
        font-size: 3.5rem;
        font-weight: bold;
        margin-top: 5rem;
        margin-bottom: 2rem;
        color: #FFFFFF;
    }
    .unsupported-browser-page-para {
        font-size: 1.7rem;
        margin-bottom: 1rem;
    }
    .unsupported-browser-page li {
        margin-bottom: 1rem;
    }
    .unsupported-browser-page ul {
        font-size: 1.5rem;
        margin-left: 3.5rem;
    }
}
