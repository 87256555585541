@import '~antd/dist/antd.css';
@import 'variables';

body {
    @include font;
}

.form-input-label {
    @include font;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 14px;
    letter-spacing: 0.15em;
    color: $text-color--primary;
    padding-bottom: $spacing-md;
}

.ant-input {
    border-radius: $border-radius-md;
    border: none;
    background: $color-primary;
    font-size: $font-size-lg;
    // padding-top: $spacing-md;
    // padding-bottom: $spacing-md;
    height: 68px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 27px;
    display: flex;
    align-items: center;
    padding: 20px;
}

.ant-input-affix-wrapper.password-input {
    background: $color-primary;
    border: none;
    padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;

}

.ant-input-affix-wrapper.password-input:hover {
    background: $color-primary;
    border: none;
}

.ant-input-password {
    border: none;

}

.ant-layout {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow: hidden;

    .ant-layout-content {
        flex-grow: 1;
    }
}

.ant-btn-primary {
    border-radius: $border-radius-md;
    background: #6A29FF;
    border: none;
}

.ant-btn-primary:hover {
    background: #6A29FF;
}

.ant-checkbox-wrapper {
    font-size: $font-size-md;
}

.ant-space-horizontal {
    gap: 24px;
}

.logo {
    margin-top: 20px;
    margin-left: 20px;
}

.ppl-btn {
    box-sizing: content-box;
    padding: 20px 24px;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    border-radius: $border-radius-md; 
    background: #6A29FF;
    border: 1px solid;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
 
    &:disabled{
        color: #00000040;
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
      }
}

.ant-btn::before {
    background: #6A29FF !important;
    border: none !important;
    color: #fff !important;
    opacity: 0.1;
}

.error-message {
    display: block;
    color: rgb(235, 87, 87);
    font-size: 15px;
}
.overlay-vtry-text {
    text-align: center;
    margin: 10px 5px;
    clear: both;
    display: block;
    padding: 2%;
    font-family: "IBMPlexSans-Medium";
    font-size: 14px;
    /* line-height: 46px; */
    letter-spacing: 1.5px;
    color: #fff;
    opacity: 1;
  }
  .overlay-vtry {
    position: absolute;
    width: 57%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 112px), -50%);
    -ms-transform: translate(calc(-50% - 112px), -50%);
    background-color: #000000;
    z-index: 2002;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 5px;
    -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  }

  img {
    width: fit-content;
    margin: auto;
    max-height: 100%;
  }

  .zoom-viewer-main {
    height: 100%;
}
