$color-primary: #f7f8fe;
$color-secondary: #ffffff;
$color-neutral: #c1c4d5;
$color-focus: #d1e9ff; //color when input elements are focussed
$color-btn: #6A29FF;

$font-size-sm: 12px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-xl: 24px;

$font-weight-normal: 400;
$font-weight-bold: 700;

$text-color--primary: #000000;

// spacing
$spacing-sm: 4px;
$spacing-md: 8px;
$spacing-lg: 12px;
$spacing-xl: 16px;
$spacing-xxl: 25px;


$border-radius-md: 8px;

// ****************************
// Generic colors
// ****************************

$ppl-white:#ffffff;
$ppl-color-black:#000000;
$card-gradient-navy-blue: linear-gradient(100.94deg, #0C1723 3.64%, #07224B 95.62%);
$card-gradient-navy-transparent: linear-gradient(90.69deg, rgba(254, 254, 254, 0.1) 0.27%, rgba(226, 226, 226, 0.2) 99.69%);

// ****************************
//
// ****************************
@mixin font {
    font-family: 'Nunito Sans', sans-serif;
}
