.savedcard {
  border: 1px solid #6A29FF;
  border-radius: 14px;
  padding: 6px;
  // margin: 10px;
  background: #FFFFFF;
  width: 215px;
  background-clip: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74px;
  gap: 16px;

  .card-last4 {
    // font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;

    color: #000000;
  }
}

.close-icon {
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
}