.terms-container{
    border: none;
    background: #fff;
   
   h4{
    font-size:16px;
    font-weight:700;
   }
   h5{
    font-size:12.8px;
    font-weight:700;
    margin-top:26px;
   }
   p{
    font-size:14px;
   }
}

   .heading-center{
       text-align: center;
       text-transform: capitalize;
       font-size: 18px;
       font-weight: bolder;
       margin: 20px auto;
       color: #000;
       font-family: 'Nunito Sans', sans-serif;
   }
   .content-wrapper{
       margin: 50px;
   }
   .content-wrapper p{
       text-indent: 30px;
       text-align:inherit;
       color: #000;
       font-family: "";
       margin: 12px 0px 0px;
   }
   .content-wrapper ol{
       padding: 0px 15px;
       color: #000;
   }
   .content-wrapper ol li{
       text-indent: 30px;
       color: #000;
       font-family: 'Nunito Sans', sans-serif;
       margin: 30px 0px;
   }
   .content-wrapper ul li{
       text-indent: 30px;
       list-style-type: none;
       margin:10px 0px;
   } 
