@import "src/variables";

.invoice-left-content {
  background: transparent url("../../../assets/images/login_bg.png") 60% 100% no-repeat padding-box;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  flex: 1.5;

  .invoice-logo {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.invoice-right-content {
  flex: 2.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding: 20px;
  position: relative;

  .invoice-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
  }

  .invoice-header-menu {
    position: absolute;
    right: 10px; 
    top: 9px;
    cursor: pointer;
  }



  .ant-table-thead > tr > th {
    background-color: #f0f0f0;
    font-weight: bold;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
  }

  .invoice-download-btn {
    box-sizing: content-box;
    padding: $spacing-md;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    border-radius: $border-radius-md;
    background: #6a29ff !important;
    border: none;
    color: #fff;
    cursor: pointer;
    width: 100px;
    height: 40px;
  }

  .invoice-cancel-btn {
    position: absolute;
    bottom: 50px;
    left: 50%; 
    transform: translateX(-50%);
    box-sizing: content-box;
    padding: $spacing-md;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    border-radius: $border-radius-md;
    color: #6a29ff;
    border: 1px solid #6a29ff;
    background: #fff;
    width: 100px;
  }
}
