.confirmationDialog:global(.ui.modal),
.confirmationDialog:global(.ui.modal)>:global(.header),
.confirmationDialog:global(.ui.modal)>:global(.content),
.confirmationDialog:global(.ui.modal)>:global(.actions) {
  color: #707070;
  background-color: #1b1b1b;
}

.confirmationDialog:global(.ui.modal)>:global(.content) {
  line-height: 26px;
  letter-spacing: 1.4px;
  font-size: 16px;
}

.confirmationDialog:global(.ui.modal)>:global(.actions)>button:hover {
  background-color: #6A29FF;
  color: #f4f4f4;
}