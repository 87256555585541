html {
  font-size: 10px;
}

html,
body {
  padding: 0;
  margin: 0;
}

html,
body,
#root,
.App,
.fullHeight {
  height: 100%;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1;
}

//h1 {
//  display: block;
//  width: 100%;
//  padding: 0;
//  margin: 0 auto 2rem;
//  font-size: 3.0rem;
//  line-height: 3.2rem;
//  color: #000000;
//  text-align: left;
//  font-weight: 400;
//  text-transform: uppercase;
//  border-bottom: #d3d3d3;
//}
//
//h2 {
//  display: block;
//  width: 100%;
//  padding: 0;
//  margin: 0 auto 2rem;
//  font-size: 2.5rem;
//  line-height: 2.7rem;
//  color: #000000;
//  text-align: left;
//  font-weight: 400;
//  text-transform: uppercase;
//  border-bottom: #d3d3d3;
//}

a {
  text-decoration: none;
  cursor: pointer;
}

a,
a:hover {
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.main {
  width: 100%;
  height: calc(100% - 6.5rem);
  max-width: 1450px;
  padding: 0 10px;
  margin: 0 auto;
}

.controlPanel {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  padding: 3rem 0 6rem 25.0rem;
  border-bottom: 1px solid #d3d3d3;

  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 25.0rem;
    height: 100%;
    z-index: 1;
    border-right: 1px solid #d3d3d3;
  }

  .content {
    padding: 0 10px;
  }
}

.adminLayout {
  height: calc(100% - 6.5rem - 5.8rem - 3.6rem);
}

.dashboardLayout {
  height: calc(100% - 6.5rem - 5.8rem);
}

@media only screen and (max-width: 980px) {
  html {
    font-size: 9px;
  }

  .controlPanel {
    padding: 0 10px;

    .sidebar {
      position: relative;
      width: 100%;
      border: none;
      margin-bottom: 2.5rem;
    }
  }
}

.flex {
  display: flex;
}

.inline {
  display: inline;
}

.space-between {
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.register-link {
  &.ant-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    padding: 0;

    span {
      text-decoration-line: underline;
    }
  }
}

.support-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
  .disabled {
    color:#808080;
  }
}

.editor-guest-credentials {
  color: #ff0000;
}

.uppercase {
  text-transform: uppercase;
}

.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: 100% !important;
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-dot {
  margin: -37px !important;
}

.ant-select {
  border-radius: 8px !important;
  border: none !important;

  .ant-select-item-option {
    height: 49px !important;
    border: 1px solid #EDEDED !important;
  }

  .ant-select-selector {
    background-color: #F7F8FE !important;
    height: 68px !important;
    display: flex;
    align-items: center;
    border: none !important;
    border-radius: 8px !important;

    .ant-select-selector:focus {
      border: none !important;
      border-radius: 8px !important;
    }

    .ant-select-selection-item {
      height: 27px;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;

    }
  }
}

.ant-select-item.ant-select-item-option+.ant-select-item.ant-select-item-option {
  border-bottom: 1px solid #EDEDED;
}

.ant-picker.ant-picker-disabled {
  background-color: #ffffff !important;
}